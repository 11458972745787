import { render, staticRenderFns } from "./non-empty.vue?vue&type=template&id=72ed8005&scoped=true&"
import script from "./non-empty.vue?vue&type=script&lang=js&"
export * from "./non-empty.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72ed8005",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CImage: require('@chakra-ui/vue').CImage, CText: require('@chakra-ui/vue').CText, CButton: require('@chakra-ui/vue').CButton, CFlex: require('@chakra-ui/vue').CFlex, CIcon: require('@chakra-ui/vue').CIcon, CBox: require('@chakra-ui/vue').CBox})
