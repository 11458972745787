<template>
  <c-flex
    flex="1"
    flex-dir="column"
    align-items="center"
    justify-content="center"
  >
    <c-image
      :src="require('../img-meal-plan-empty.svg')"
      alt="meal plan empty illustration"
      w="200px"
    />
    <h1
      v-chakra
      mt="10px"
      color="#111"
      font-weight="bold"
      font-size="16px"
      font-family="body"
    >
      Rekomendasi menumu masih kosong.
    </h1>
    <c-text
      mt="10px"
      max-w="328px"
      text-align="center"
      font-size="14px"
      color="#828282"
    >
      Ahli gizi kami masih merencanakan rekomendasi menu sesuai preferensimu.
      Mohon ditunggu, ya!
    </c-text>
  </c-flex>
</template>

<script>
export default {
  name: "RecommendedMenuPage",
};
</script>
